<template>
  <div>
    <h4>products_to_fix</h4>
    <b-card>
      <b-button @click="load_missing()" class="mr-1"
        >Load Missing Params</b-button
      >
      <b-button @click="list_params('brand')" class="mr-1">brand</b-button>
      <b-button @click="list_params('categories')" class="mr-1">
        categories
      </b-button>
      <b-button @click="list_params('item_type')" class="mr-1">
        item_type
      </b-button>
      <b-button @click="list_slug()" class="mr-1"> slug </b-button>
      <b-button @click="toggleSearchMode()" class="mr-1"> Search Mode</b-button>
      <p>camera_type , lens_type , item_type , brand</p>

      <div class="row mt-2" v-if="search_mode">
        <div class="col">
          <span>key</span>
          <b-form-input class="mr-1" v-model="search_form.key"> </b-form-input>
        </div>
        <div class="col">
          <span>value</span>
          <b-form-input class="mr-1" v-model="search_form.value">
          </b-form-input>
        </div>
        <div class="col">
          <b-form-checkbox v-model="search_form.not_exist">
            not_exist
          </b-form-checkbox>
          <b-form-checkbox v-model="search_form.web_enable">
            web_enable
          </b-form-checkbox>
        </div>
        <div class="col">
          <br />
          <b-button
            variant="relief-success"
            @click="find('item_type')"
            class="mr-1"
          >
            Search
          </b-button>
        </div>
      </div>

      <div v-if="product_distinct.length">
        <br /><br />
        <br />
        {{ product_distinct }}
      </div>
    </b-card>
    <div class="table-responsive" v-if="mode === 'slug'">
      length: {{ slug.length }}
      <table class="table">
        <thead>
          <tr>
            <td>ชื่ออุปกรณ์</td>
            <td width="50%">slug</td>
            <td>Save</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(x, index) in slug" :key="index">
            <td>
              {{ x.web_name }}
              <b-form-input v-model="m_web_name[x._id]"> </b-form-input>
            </td>
            <td>
              <b-form-input v-model="m_slug[x._id]"> </b-form-input>
              <b-button
                v-if="!loading"
                variant="relief-success"
                class="mr-1"
                @click="generateSlug(x._id)"
                size="sm"
              >
                gen slug
              </b-button>
            </td>
            <td>
              <b-button
                v-if="!loading"
                variant="relief-success"
                class="mr-1"
                @click="SaveSlug(x._id)"
              >
                Save
              </b-button>
              <b-spinner v-if="loading" class="mr-1" />
              <b-button
                variant="relief-success"
                disabled
                class="mr-1"
                v-if="loading"
              >
                Save
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    {{ mode }} <br />

    lens <br />
    item_type,brand,mount,fullframe_or_crop,lens_type <br /><br />
    camera <br />
    item_type,brand,camera_type,sensor_size,video_resolution,frame_rate
    <br /><br />

    <div
      class="table-responsive"
      v-if="mode === 'missing-params' || mode === 'search'"
    >
      <table class="table" v-if="search_form.not_exist === false">
        <thead>
          <tr>
            <td>ชื่ออุปกรณ์</td>
            <td width="20%">ประเภท</td>
            <td width="20%">ยี่ห้อ</td>
            <td width="20%">item_type</td>
            <td>Attributes</td>
            <td width="5%">Save</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(x, index) in products_to_fix" :key="index">
            <td>{{ x.web_name }}</td>
            <td>
              <b-form-group label-for="vue-select">
                <v-select
                  v-model="selectcategories[x._id]"
                  :options="product_categories_options"
                  :clearable="false"
                />
              </b-form-group>
            </td>
            <td>
              <b-form-input v-model="m_brand[x._id]"> </b-form-input>
            </td>
            <td>
              <b-form-input v-model="m_item_type[x._id]"> </b-form-input>
            </td>
            <td>sdfs</td>
            <td>
              <b-button
                v-if="!loading"
                variant="relief-success"
                class="mr-1"
                @click="Save(x._id)"
              >
                Save
              </b-button>
              <b-spinner v-if="loading" class="mr-1" />
              <b-button
                variant="relief-success"
                disabled
                class="mr-1"
                v-if="loading"
              >
                Save
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>

      {{ products_to_fix.length }}

      <table class="table" v-if="search_form.not_exist === true">
        <thead>
          <tr>
            <td>ชื่ออุปกรณ์</td>
            <td>Attributes</td>
            <td width="5%">Save</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(x, index) in products_to_fix" :key="index">
            <td>{{ x.web_name }}</td>
            <td>
              {{ x.attribute }} <br />{{ x.item_type }}
              {{ m_att.lens_type[x._id] }}
              <b-form-tags
                v-model="m_att.lens_type[x._id]"
                no-outer-focus
                separator=",;"
                remove-on-delete
                placeholder="Key"
              >
              </b-form-tags>
            </td>
            <td>
              <b-button
                variant="relief-warning"
                class="mr-1"
                @click="open_new(x._id)"
              >
                Open
              </b-button>
              <b-button
                v-if="!loading"
                variant="relief-success"
                class="mr-1"
                @click="SaveAttribute(x._id)"
                size="sm"
              >
                Update ATT
              </b-button>
              <b-spinner v-if="loading" class="mr-1" />
              <b-button
                variant="relief-success"
                disabled
                class="mr-1"
                v-if="loading"
                size="sm"
              >
                Update ATT
              </b-button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ProductCategories from "@/assets/product_categories.json";
import _ from "lodash";
import {
  BFormSelect,
  BButton,
  BFormGroup,
  BSpinner,
  BFormInput,
  BCard,
  BFormRadio,
  BFormTags,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BFormSelect,
    BButton,
    vSelect,
    BSpinner,
    BFormGroup,
    BFormInput,
    BCard,
    BFormRadio,
    BFormTags,
    BFormCheckbox,
  },
  data() {
    return {
      loading: false,
      att_mode: false,
      price_data: [],
      selectcategories: [],
      product_categories_options: ProductCategories,
      products_to_fix: [],
      m_brand: [],
      m_item_type: [],
      m_slug: [],
      m_web_name: [],
      mode: "missing-params",
      product_distinct: [],
      search_form: {
        not_exist: true,
        web_enable: true,
        key: "item_type",
      },
      search_mode: true,
      slug: [],
      att: {
        filter_mode: "not_exist",
        search_term: [],
      },
      test: [],
      m_att: {
        lens_type: [],
      },
    };
  },
  created() {
    this.load_data("missing-params", {});
  },
  methods: {
    open_new(id) {
      window.open("/product/view/" + id, "_blank");
    },
    find() {
      this.mode = "search";
      this.load_data("search", this.search_form);
    },
    list_att() {
      const form_data = {
        key: this.search_form.key,
        // value: this.search_form.value,
      };
      // this.mode = "search";
      console.log(form_data);
      this.load_data("search_att", form_data);
    },

    load_missing() {
      this.mode = "missing-params";
      this.search_form = {};
      this.load_data("missing-params", {});
    },

    toggleSearchMode() {
      this.search_mode = !this.search_mode;
    },
    attributeMode() {
      this.att_mode = !this.att_mode;
      this.mode = "att";
    },
    list_params(key) {
      this.$http({
        method: "POST",
        url: "product/list-params_group",
        data: {
          key: key,
        },
      }).then((x) => {
        this.product_distinct = x.data.data;
      });
    },
    load_data(mode, form_data) {
      const form = {};
      form.mode = mode;
      form.data = form_data;
      this.$http({
        method: "POST",
        url: "product/bulk-product-to-fix",
        data: form,
      }).then((x) => {
        console.log(x.data.data);
        this.products_to_fix = x.data.data;
        this.products_to_fix.forEach((product) => {
          this.$set(
            this.selectcategories,
            product._id,
            product.categories || ""
          );
          this.$set(this.m_brand, product._id, product.brand || "");
          this.$set(this.m_item_type, product._id, product.item_type || "");
          // this.selectcategories[product._id] = product.categories || "";
          // this.m_brand[product._id] = product.brand || "";
          // this.m_item_type[product._id] = product.item_type || "";
        });
      });
    },
    load_att() {
      this.$http({
        method: "POST",
        url: "product/bulk-product-to-fix",
        data: form,
      });
    },
    async SaveAttribute(id) {
      this.loading = true;

      // m_att: {
      //   lens_type: [],
      // },
      console.log(this.m_att.lens_type[id]);
      const checkbox_true = _.omitBy(this.checkbox, (value, key) => {
        return !value;
      });
      const arr_key = _.keys(checkbox_true);

      const put_form = _.pickBy(this.form, function (v, k) {
        return _.includes(arr_key, k);
      });

      const arr = [];
      await Object.entries(put_form).forEach((x) => {
        const key = x[0];
        const pre_values = x[1];
        const value = [];
        pre_values.forEach((y) => {
          arr.push({
            key: key,
            value: y,
          });
        });
      });

      this.$http({
        method: "PUT",
        url: `/product/` + id,
        data: {
          categories: this.selectcategories[id],
          item_type: this.m_item_type[id],
          brand: this.m_brand[id],
        },
      })
        .then((x) => {
          this.loading = false;
          this.load_data(this.mode, this.search_form);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    Save(id) {
      this.loading = true;
      this.$http({
        method: "PUT",
        url: `/product/` + id,
        data: {
          categories: this.selectcategories[id],
          item_type: this.m_item_type[id],
          brand: this.m_brand[id],
        },
      })
        .then((x) => {
          this.loading = false;
          this.load_data(this.mode, this.search_form);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    SaveSlug(id) {
      this.loading = true;
      this.$http({
        method: "PUT",
        url: `/product/` + id,
        data: {
          web_name: this.m_web_name[id],
          slug: this.m_slug[id],
        },
      })
        .then((x) => {
          this.loading = false;
          this.list_slug();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    list_slug() {
      this.mode = "slug";
      this.$http({
        method: "POST",
        url: "product/slug-list",
      }).then((x) => {
        this.slug = x.data.data;
        this.slug.forEach((zz) => {
          this.$set(this.m_slug, zz._id, zz.slug || "");
          this.$set(this.m_web_name, zz._id, zz.web_name || "");
        });
      });
    },
    generateSlug(id) {
      this.m_slug[id] = this.m_web_name[id]
        .toString() // Convert to string
        .trim() // Remove leading and trailing spaces
        .toLowerCase() // Convert to lowercase
        // Replace periods with hyphens
        .replace(/\./g, "-")
        .replace(/\//g, "-") // Replace slashes with hyphens
        // Remove accents, diacritics
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        // Replace spaces and consecutive spaces with a single hyphen
        .replace(/\s+/g, "-")
        // Remove all non-word chars except hyphen
        .replace(/[^\w\-]+/g, "")
        // Replace multiple - with single -
        .replace(/\-\-+/g, "-")
        // Remove leading and trailing -
        .replace(/^[-]+|[-]+$/g, "");
    },
  },
};
</script>
<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
</style>
